import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Mainnet',
    icon: 'HomeIcon',
    href: 'https://dex.benswap.cash/'
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap'
      },
      {
        label: 'Liquidity',
        href: '/pool'
      }
    ]
  },
  {
    label: 'Ecosystem',
    icon: 'BenIcon',
    items: [
      {
        label: "Ben Token (BSC)",
        href: "https://bentoken.finance/",
      },
      {
        label: "BenSwap (BSC)",
        href: 'https://benswap.finance/',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: "Github",
        href: "https://github.com/BenTokenFinance",
      },
      {
        label: "Docs",
        href: "https://docs.benswap.cash",
      }
    ],
  },
]

export default config
